const USER_KEY = '@TCAuthSalesCounter:user';

const UserStorage = {
  getUser() {
    return JSON.parse(localStorage.getItem(USER_KEY));
  },

  saveUser(user) {
    localStorage.setItem(USER_KEY, JSON.stringify(user));
  },

  getUserID() {
    return JSON.parse(localStorage.getItem('sub'));
  },

  saveUserID(user) {
    localStorage.setItem("sub", JSON.stringify(user));
  },

  getUserPDV() {
    return JSON.parse(localStorage.getItem('subPDV'));
  },

  saveUserPDV(user) {
    localStorage.setItem("subPDV", JSON.stringify(user));
  },

  removeUser() {
    localStorage.removeItem(USER_KEY);
    localStorage.removeItem("sub");
    localStorage.removeItem("subPDV");


  },
};

export default UserStorage;
