import React, { createContext, useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import jwtDecode from 'jwt-decode';
import { useSnackbar } from 'notistack';
import ApiService from '../../services/API';
import { TokenService, UserService } from '../../services/Storage';
import AuthService from '../../services/Session';

export const SessionContext = createContext();

const userDefault = {
  identificationName: '',
  tradeName: '',
  responsibleName: '',
  email: '',
};

export const SessionProvider = (props) => {
  const { children } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userAuthenticated, setUserAuthenticated] = useState(userDefault);
  const [loading, setLoading] = useState(true);
  const [dataTable, setDataTable] = useState([])

  function getUserAuthenticated() {
    const user = UserService.getUser();
    setUserAuthenticated(user);
  }

  async function getUserInfo() {
    try {
    const { data } = await ApiService.get('/sales-counter/me');
      const userData = {
        identificationName: data.data.identification_name,
        tradeName: data.data.trade_name,
        responsibleName: data.data.responsible_name,
        email: data.data.email,
        owerId: data.data.owner_id,
      };

      setUserAuthenticated(userData);
      UserService.saveUser(userData);
      UserService.saveUserPDV(data.data.owner.user_id)

      return data;
    } catch(err) {
      throw err;
    }
  }

  async function signIn(email, password) {
    return AuthService.login(email, password).then((response) => {
      ApiService.setHeader(response.access_token)
      getUserInfo().then((result) => {
        UserService.saveUserID(response.sub)
        TokenService.saveToken(response.access_token);
        TokenService.saveRefreshToken(response.refresh_token);
        setIsAuthenticated(true)
      }).catch((error) => {
        enqueueSnackbar(
          error.response?.data.error || 'Para acesso solicite a ativação de sua Smartbank! Suporte +5541992859597',
          {
            variant: 'error',
          },
        );
      })
      return response;
    }).catch((error) => {
      throw error
    })
  }

  function signOut() {
    setUserAuthenticated(userDefault);
    setIsAuthenticated(false);
    AuthService.logout().then(() => {
      TokenService.removeToken()
    })
    UserService.removeUser()
  }

  useEffect(() => {
    try {
      const token = TokenService.getRefreshToken();
      if (token) {
          ApiService.setHeader();
          getUserAuthenticated();
          setIsAuthenticated(true);
      }
    } catch (error) {
      enqueueSnackbar(
        'Token de acesso inválido. Favor realizar o login novamente.',
        {
          variant: 'error',
        },
      );
    }
    setLoading(false);
  }, []);

  return (
    <SessionContext.Provider
      value={{ loading, isAuthenticated, userAuthenticated, signIn, signOut, dataTable, setDataTable }}
    >
      {children}
    </SessionContext.Provider>
  );
};

SessionProvider.propTypes = {
  children: PropTypes.object.isRequired,
};

export default () => useContext(SessionContext);
