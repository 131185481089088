import {
  AuthenticationError,
  ConnectionError,
  ConflictError,
  BadRequestError,
  CredentialError
} from './Errors';

export default {
  handleResponseError(error) {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          if(error.response.data.error === "Credenciais inválidas"){
            throw new CredentialError(
              error.response.status,
              error.response.data.error
            )
          }
          throw new AuthenticationError(
            error.response.status,
            error.response.data.error.trim(),
          );
        case 400:
          throw new BadRequestError(
            error.response.status,
            error.response.data.error,
          );
        case 409:
          throw new ConflictError(
            error.response.status,
            error.response.data.messages[0],
          );
        default:
          throw new Error(
            `Problemas na a requisição com servidor de dados. Código: ${error.response.status}`,
          );
      }
    }
    throw new ConnectionError();
  },
};
